import { UrlObject } from 'url'
import { LINKAREER_ACADEMY_BASE_URL, XEN_HOSTNAME } from 'utils/config'

export enum NavBarActiveKey {
  ROOT,
  CHANNEL,
  CHANNEL_INTEREST,
  RECRUIT_HOME,
  COMMUNITY,
  INTERN,
  RECRUIT,
  ACTIVITY,
  CONTEST,
  EDUCATION,
  CLUB,
  CALENDAR_RECRUIT,
  CALENDAR_ACTIVITY,
  COVER_LETTER,
  SELF_INTRODUCTION,
  CAREER_MANAGEMENT,
  CBT,
  CBT_TEST,
  CBT_ARCHIVE,
  CBT_REVIEW,
  CBT_NOTE,
  PREDICTION,
}

export type TDesktopHeader = {
  size: 'small' | 'large'
  isSticky?: boolean
  searchBar?: boolean
  externalNavBar?: boolean
}

export type TMobileHeader = {
  isSticky?: boolean
  searchBar?: boolean
}

export type TNavBar = {
  isSticky?: boolean
  activeKey: NavBarActiveKey
}

export type TDesktopRenderOptions = {
  Header?: TDesktopHeader
  NavBar?: TNavBar
  Footer?: boolean
}

export type TMobileRenderOptions = {
  Header?: TMobileHeader
  NavBar?: TNavBar
  Footer?: boolean
  TabBar?: boolean
  BottomFixedAd?: boolean
}

export enum NavBarBadge {
  NONE,
  NEW,
  COUNT,
  CBT,
}

export enum NavBarType {
  LINK,
  HOVER,
}

export const ACTIVITIES_TAB = [
  NavBarActiveKey.INTERN,
  NavBarActiveKey.RECRUIT,
  NavBarActiveKey.ACTIVITY,
  NavBarActiveKey.CONTEST,
  NavBarActiveKey.EDUCATION,
  NavBarActiveKey.CLUB,
]

export type TLinkMenuItem = {
  type: NavBarType.LINK
  label: string
  href: any
  active: NavBarActiveKey
  badge?: NavBarBadge
}

export interface NavMenuItemType {
  label: string
  href: UrlObject
  badge?: NavBarBadge
  active: NavBarActiveKey
}

export interface HeaderNavItemType extends NavMenuItemType {
  menuItems?: NavMenuItemType[]
}

// TODO url 전부 알맞게 수정하기
export const NAV_BAR_MENU_ITEMS: HeaderNavItemType[] = [
  {
    label: '신입/인턴',
    href: { pathname: '/recruit-home' },
    badge: NavBarBadge.NONE,
    active: NavBarActiveKey.RECRUIT_HOME,
    menuItems: [
      {
        label: '홈',
        active: NavBarActiveKey.RECRUIT_HOME,
        href: { pathname: '/recruit-home' },
      },
      {
        label: '인턴',
        active: NavBarActiveKey.INTERN,
        href: { pathname: '/list/intern' },
      },
      {
        label: '신입',
        active: NavBarActiveKey.RECRUIT,
        href: { pathname: '/list/recruit' },
      },
      {
        label: '공고달력',
        active: NavBarActiveKey.CALENDAR_RECRUIT,
        href: {
          pathname: '/calendar',
          query: { activityType: 'recurit', parentActiveTab: 'recurit' },
        },
      },
      {
        label: '시험 합격예측',
        active: NavBarActiveKey.PREDICTION,
        href: { pathname: '/prediction/gsat2024-2' },
      },
    ],
  },
  {
    label: '대외활동/교육/공모전',
    active: NavBarActiveKey.ACTIVITY,
    href: { pathname: `/list/activity` },
    menuItems: [
      {
        label: '대외활동',
        active: NavBarActiveKey.ACTIVITY,
        href: { pathname: '/list/activity' },
      },
      {
        label: '교육',
        active: NavBarActiveKey.EDUCATION,
        href: { pathname: '/list/education' },
      },
      {
        label: '공모전',
        active: NavBarActiveKey.CONTEST,
        href: { pathname: '/list/contest' },
      },
      {
        label: '동아리',
        active: NavBarActiveKey.CLUB,
        href: { pathname: '/list/club' },
      },
      {
        label: '공고달력',
        active: NavBarActiveKey.CALENDAR_ACTIVITY,
        href: {
          pathname: '/calendar',
          query: { activityType: 'activity', parentActiveTab: 'activity' },
        },
      },
    ],
  },
  {
    label: 'CBT',
    active: NavBarActiveKey.CBT,
    badge: NavBarBadge.CBT,
    href: { pathname: LINKAREER_ACADEMY_BASE_URL },
    menuItems: [
      {
        label: '시험 CBT',
        active: NavBarActiveKey.CBT_TEST,
        href: { pathname: `${LINKAREER_ACADEMY_BASE_URL}` },
      },
      {
        label: '나의 관심 시험',
        active: NavBarActiveKey.CBT_ARCHIVE,
        href: { pathname: `${LINKAREER_ACADEMY_BASE_URL}/mycbt/archive` },
      },
      {
        label: 'CBT 결과보기',
        active: NavBarActiveKey.CBT_REVIEW,
        href: { pathname: `${LINKAREER_ACADEMY_BASE_URL}/mycbt/review` },
      },
      {
        label: '오답노트',
        active: NavBarActiveKey.CBT_NOTE,
        href: { pathname: `${LINKAREER_ACADEMY_BASE_URL}/mycbt/note` },
      },
    ],
  },
  {
    label: '커뮤니티',
    badge: NavBarBadge.NONE,
    active: NavBarActiveKey.COMMUNITY,
    href: { pathname: `https://${XEN_HOSTNAME}/community` },
    menuItems: [],
  },
  {
    label: '자기소개서',
    href: { pathname: '/cover-letter/search' },

    active: NavBarActiveKey.COVER_LETTER,
    menuItems: [
      {
        label: '자소서 만능 검색기',
        href: { pathname: '/cover-letter/search' },
        active: NavBarActiveKey.COVER_LETTER,
      },
      {
        label: '자소서 작성',
        href: { pathname: '/self-introduction' },
        active: NavBarActiveKey.SELF_INTRODUCTION,
      },
    ],
  },
  {
    label: '멘토 게시판',
    badge: NavBarBadge.NEW,
    active: NavBarActiveKey.COMMUNITY,
    href: { pathname: `https://${XEN_HOSTNAME}/mentoring` },
    menuItems: [
      {
        label: '취업/문과 고민',
        href: { pathname: `https://${XEN_HOSTNAME}/mentoring` },
        active: NavBarActiveKey.COMMUNITY,
      },
      {
        label: '취업/이과 고민',
        href: { pathname: `https://${XEN_HOSTNAME}/STEM_mentoring` },
        active: NavBarActiveKey.COMMUNITY,
      },
      {
        label: '인턴 고민',
        href: { pathname: `https://${XEN_HOSTNAME}/mentor_intern` },
        active: NavBarActiveKey.COMMUNITY,
      },
      {
        label: '삼성 멘토질문방',
        href: { pathname: `https://${XEN_HOSTNAME}/mento_samsung` },
        active: NavBarActiveKey.COMMUNITY,
      },
    ],
  },
  {
    label: '채널',
    href: { pathname: '/channel', query: { tab: 'home' } },
    badge: NavBarBadge.NONE,
    active: NavBarActiveKey.CHANNEL,
    menuItems: [
      {
        label: '홈',
        href: { pathname: '/channel', query: { tab: 'home' } },
        active: NavBarActiveKey.CHANNEL,
      },
      {
        label: '내 관심 소식',
        href: { pathname: '/channel', query: { tab: 'interest' } },
        active: NavBarActiveKey.CHANNEL_INTEREST,
      },
    ],
  },
]

export const MOBILE_MENU_ITEMS: HeaderNavItemType[] = [
  {
    label: '신입/인턴',
    href: { pathname: '/recruit-home' },
    badge: NavBarBadge.NONE,
    active: NavBarActiveKey.RECRUIT_HOME,
    menuItems: [
      {
        label: '홈',
        active: NavBarActiveKey.RECRUIT_HOME,
        href: { pathname: '/recruit-home' },
      },
      {
        label: '인턴',
        active: NavBarActiveKey.INTERN,
        href: { pathname: '/list/intern' },
      },
      {
        label: '신입',
        active: NavBarActiveKey.RECRUIT,
        href: { pathname: '/list/recruit' },
      },
      {
        label: '공고달력',
        active: NavBarActiveKey.CALENDAR_RECRUIT,
        href: {
          pathname: '/calendar',
          query: { activityType: 'recurit' },
        },
      },
      {
        label: '시험 합격예측',
        active: NavBarActiveKey.PREDICTION,
        href: { pathname: '/prediction/gsat2024-2' },
      },
    ],
  },
  {
    label: '대외활동/교육/공모전',
    active: NavBarActiveKey.ACTIVITY,
    href: { pathname: `/list/activity` },
    menuItems: [
      {
        label: '대외활동',
        active: NavBarActiveKey.ACTIVITY,
        href: { pathname: '/list/activity' },
      },
      {
        label: '교육',
        active: NavBarActiveKey.EDUCATION,
        href: { pathname: '/list/education' },
      },
      {
        label: '공모전',
        active: NavBarActiveKey.CONTEST,
        href: { pathname: '/list/contest' },
      },
      {
        label: '동아리',
        active: NavBarActiveKey.CLUB,
        href: { pathname: '/list/club' },
      },
      {
        label: '공고달력',
        active: NavBarActiveKey.CALENDAR_ACTIVITY,
        href: {
          pathname: '/calendar',
          query: { activityType: 'activity' },
        },
      },
    ],
  },
  {
    label: 'CBT',
    active: NavBarActiveKey.CBT,
    href: { pathname: LINKAREER_ACADEMY_BASE_URL },
    menuItems: [
      {
        label: '시험 CBT',
        active: NavBarActiveKey.CBT_TEST,
        href: { pathname: `${LINKAREER_ACADEMY_BASE_URL}` },
      },
      {
        label: '나의 관심 시험',
        active: NavBarActiveKey.CBT_ARCHIVE,
        href: { pathname: `${LINKAREER_ACADEMY_BASE_URL}/mycbt/archive` },
      },
      {
        label: 'CBT 결과보기',
        active: NavBarActiveKey.CBT_REVIEW,
        href: { pathname: `${LINKAREER_ACADEMY_BASE_URL}/mycbt/review` },
      },
      {
        label: '오답노트',
        active: NavBarActiveKey.CBT_NOTE,
        href: { pathname: `${LINKAREER_ACADEMY_BASE_URL}/mycbt/note` },
      },
    ],
  },
  {
    label: '채널',
    href: { pathname: '/channel', query: { tab: 'home' } },
    badge: NavBarBadge.NONE,
    active: NavBarActiveKey.CHANNEL,
    menuItems: [
      {
        label: '홈',
        href: { pathname: '/channel', query: { tab: 'home' } },
        active: NavBarActiveKey.CHANNEL,
      },
      {
        label: '내 관심 소식',
        href: { pathname: '/channel', query: { tab: 'interest' } },
        active: NavBarActiveKey.CHANNEL_INTEREST,
      },
    ],
  },
  {
    label: '커뮤니티',
    badge: NavBarBadge.NONE,
    active: NavBarActiveKey.COMMUNITY,
    href: { pathname: `https://${XEN_HOSTNAME}/community` },
    menuItems: [
      {
        label: '홈',
        href: { pathname: `https://${XEN_HOSTNAME}/community` },
        active: NavBarActiveKey.COMMUNITY,
      },
    ],
  },
  {
    label: '멘토 게시판',
    badge: NavBarBadge.NEW,
    active: NavBarActiveKey.COMMUNITY,
    href: { pathname: `https://${XEN_HOSTNAME}/mentoring` },
    menuItems: [
      {
        label: '취업/문과 고민',
        href: { pathname: `https://${XEN_HOSTNAME}/mentoring` },
        active: NavBarActiveKey.COMMUNITY,
      },
      {
        label: '취업/이과 고민',
        href: { pathname: `https://${XEN_HOSTNAME}/STEM_mentoring` },
        active: NavBarActiveKey.COMMUNITY,
      },
      {
        label: '인턴 고민',
        href: { pathname: `https://${XEN_HOSTNAME}/mentor_intern` },
        active: NavBarActiveKey.COMMUNITY,
      },
      {
        label: '삼성 멘토질문방',
        href: { pathname: `https://${XEN_HOSTNAME}/mento_samsung` },
        active: NavBarActiveKey.COMMUNITY,
      },
    ],
  },
  {
    label: '자기소개서',
    href: { pathname: '/cover-letter/search' },
    badge: NavBarBadge.NEW,
    active: NavBarActiveKey.COVER_LETTER,
    menuItems: [
      {
        label: '자소서 만능 검색기',
        href: { pathname: '/cover-letter/search' },
        active: NavBarActiveKey.COVER_LETTER,
      },
      {
        label: '자소서 작성',
        href: { pathname: '/self-introduction' },
        active: NavBarActiveKey.SELF_INTRODUCTION,
      },
    ],
  },
  {
    label: '스펙 정리하기',
    href: { pathname: '/my-career/spec-management' },
    badge: NavBarBadge.NONE,
    active: NavBarActiveKey.CAREER_MANAGEMENT,
  },
]

interface MobileSecondDepthMenuDataObjectType {
  recruit: SecondDepthMenuDataType[]
  activity: SecondDepthMenuDataType[]
}

export interface SecondDepthMenuDataType {
  label: string
  href: UrlObject | string
  active: NavBarActiveKey
}

export const MOBILE_SECOND_DEPTH_MENU_DATA_OBJECT: MobileSecondDepthMenuDataObjectType =
  {
    recruit: [
      {
        label: '홈',
        href: { pathname: '/recruit-home' },
        active: NavBarActiveKey.RECRUIT_HOME,
      },
      {
        label: '인턴',
        href: { pathname: '/list/intern' },
        active: NavBarActiveKey.INTERN,
      },
      {
        label: '신입',
        href: { pathname: '/list/recruit' },
        active: NavBarActiveKey.RECRUIT,
      },
      {
        label: '공고달력',
        active: NavBarActiveKey.CALENDAR_RECRUIT,
        href: {
          pathname: '/calendar',
          query: { activityType: 'recurit' },
        },
      },
      {
        label: '시험 합격예측',
        active: NavBarActiveKey.PREDICTION,
        href: { pathname: '/prediction/gsat2024-2' },
      },
    ],
    activity: [
      {
        label: '대외활동',
        href: { pathname: '/list/activity' },
        active: NavBarActiveKey.ACTIVITY,
      },
      {
        label: '교육',
        href: { pathname: '/list/education' },
        active: NavBarActiveKey.EDUCATION,
      },
      {
        label: '공모전',
        href: { pathname: '/list/contest' },
        active: NavBarActiveKey.CONTEST,
      },
      {
        label: '동아리',
        href: { pathname: '/list/club' },
        active: NavBarActiveKey.CLUB,
      },
      {
        label: '공고달력',
        active: NavBarActiveKey.CALENDAR_RECRUIT,
        href: {
          pathname: '/calendar',
          query: { activityType: 'activity' },
        },
      },
    ],
  }
